import React, { useState } from 'react';
import useMobileScreen from '../../hooks/useMobileScreen';
import FooterNew from '../../../../components/footer-new';

const Footer =
    ({ isHome, isShowCities, SSR }, { pathname }) =>
    () => {
        const isMobileSSR = useMobileScreen();

        return <FooterNew {...{ isHome, isShowCities, SSR, pathname, isMobileSSR, useState }} />;
    };

export default Footer;
